import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import loginPage from './pages/loginPage/loginPage';
import QrCodePage from './pages/qrCodePage/qrCodePage';
import ExamPage from './pages/examPage/examPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' Component={loginPage}/>
        <Route path='/login' Component={QrCodePage}/>
        <Route path='/exam' Component={ExamPage}/>
      </Routes>
    </Router>
  );
}

export default App;
