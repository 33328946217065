import React, { useState, useEffect } from "react";
import "./qrCodePage.css";
import logo from "../../assets/otolithics-logo.png";
import { Button, Typography, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { socket } from "../../services/socket";

const QrCodePage = () => {
  const[messageReceived, setMessageReceived] = useState("");
  const[paired, setPaired] = useState("");
  const [room, setRoom] = useState('');
  const navigate = useNavigate();


  const handleExam = () => {
    navigate("/exam", {
      state: {
        ...messageReceived
      }
    });
  }

  const handleBack = () => {
    navigate("/");
  }

  const handleInputRoom  = (event) => {
    setRoom(event.target.value);
  };
  
  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

  useEffect(() => {
      socket.on("receive_message", (data) => {
          setMessageReceived(data);
          setPaired(true);
      });
  }, []);

  return (
    <div className="base-login">
      <div className="left-content">
        <div className="otolithics-logo">
          <img src={logo} alt="logo" />
        </div>
        {//<QRscanner />
        }
        {paired === true &&
          <Typography style={{marginBottom: '15px'}}>
            Dados pareados com o Painel Cliente
          </Typography>
        }
      </div>
      <div className="right-content">
        <TextField 
          label="Digite seu código de verificação"
          id="outlined-size-normal"
          className="room-field"
          onChange={handleInputRoom}
        />
        <div className="base-button">
          <Button variant="contained" className="qr-code-button" onClick={handleExam} disabled={paired === true ? false : true}>
            Avançar
          </Button>
          <Button variant="contained" className="logout-button" onClick={handleBack}>
            Voltar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default QrCodePage;