import React from "react";
import "./loginPage.css";
import logo from "../../assets/otolithics-logo.png";
import { Button, Typography } from "@mui/material";
import font from "@fontsource/montserrat";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {

  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  }

  return (
    <div className="base-login">
      <div className="left-content">
      <div className="otolithics-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="base-text">
        <Typography className="base-text-typography" variant="h2" fontFamily={font}>
          Bem vindo ao
        </Typography>
        <Typography className="base-text-typography h1" variant="h1" fontFamily={font}>
          OTOLITHICS
        </Typography>
        {
        //<div className="base-text-description">
        //  <Typography className="base-text-typography description" fontFamily={font}>
        //    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor...
        //  </Typography>
        //</div>
        }
      </div>
      </div>
      <div className="right-content">
      <div className="base-button">
        <Button variant="contained" className="qr-code-button" onClick={handleLogin}>
          Iniciar
        </Button>
        <Button variant="contained" className="logout-button">
          Sair
        </Button>
      </div>
      </div>
    </div>
  );
};

export default LoginPage;