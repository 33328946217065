import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const LoadModal = ({ open }) => {
  return (
    <Modal
      open={open}
      onClose={() => {}}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          bgcolor: 'rgba(0, 0, 0, 0.5)', // Add a semi-transparent background
        }}
      >
      <CircularProgress color="primary" />
        <span 
          style={{ color: "white", marginTop: "10px", fontSize: "18px", textAlign: "center" }}
        >
          Ative a rotação do dispositivo e posicione-o na horizontal
        </span>
      </Box>
    </Modal>
  );
};

export default LoadModal;
