import React, { useState, useEffect, useCallback, useRef } from "react";
import "./examPage.css";
import ExamLetters from "../../components/examLetter/examLetter";
import { useNavigate, useLocation } from "react-router-dom";
import { animated } from "react-spring";
import { socket } from "../../services/socket";
import LoadModal from "../../components/loadModal/loadModal";
import circleImage from "../../assets/circles.png";
import nosleep from "nosleep.js";

const ExamPage = () => {
  const [letterExam, setLetterExam] = useState(false);
  const navigate = useNavigate();

  const [messageReceived, setMessageReceived] = useState("");
  const [presets, setPresets] = useState({});
  const [returnData, setReturnData] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [loadStep, setLoadStep] = useState();
  const [condition, setCondition] = useState();
  const [linePositionAngle, setLinePositionAngle] = useState();
  const { state } = useLocation();
  const noSleep = useRef(new nosleep());
  const room = state.room;
  const ultimaEmissao = useRef(Date.now());
  const redlineRef1 = useRef(null);
  const redlineRef2 = useRef(null);
  const valueAngleRef = useRef(0);

  useEffect(() => {
    socket.emit("join_room", room);
  }, [room]);

  useEffect(() => {
    socket.on("return_data", (data) => {
      setReturnData(data.captureData);
    });

    if (returnData === true) {
      socket.emit("return_coords", {
        ...presets,
        room,
      });
      setReturnData(false);
    }
  }, [presets, returnData, room]);

  useEffect(() => {
    const agora = Date.now();
    const umSegundo = 1000;

    if (!ultimaEmissao.current || agora - ultimaEmissao.current > umSegundo) {
      ultimaEmissao.current = agora;
      socket.emit("avatar_position", {
        presets,
        room,
      });
    }
  }, [presets, room]);

  useEffect(() => {
    socket.on("send_line_position", (data) => {
      setLinePositionAngle(data?.linePosition);
    });

    socket.on("receive_message", (data) => {
      setCondition(data.examDataObj);
    });
  }, []);

  useEffect(() => {
    if (typeof DeviceOrientationEvent.requestPermission === "function") {
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === "granted") {
            setMessageReceived("Permissão ativa");
          } else {
            setMessageReceived("Permissão inativa");
          }
        })
        .catch(console.error);
    } else {
      setMessageReceived("Não foi possível");
    }

    window.addEventListener("deviceorientation", handleOrientationChange);

    if (messageReceived === "Permissão inativa") {
      navigate("/login");
    }
  }, [messageReceived, navigate]);

  const handleOrientationChange = (event) => {
    const { alpha, beta, gamma } = event;

    setPresets({
      alpha: alpha,
      beta: beta,
      gamma: gamma,
    });
  };

  useEffect(() => {
    noSleep.current.enable();
  }, []);

  let effectiveBeta = 0;

  if (presets.gamma > 0) {
    effectiveBeta = -presets.beta;
  } else {
    effectiveBeta = presets.beta;
  }

  useEffect(() => {
    const valueAngle = linePositionAngle - effectiveBeta;
    valueAngleRef.current = valueAngle;

    if (redlineRef1.current) {
      redlineRef1.current.style.transform = `translate(0%, 0%) rotate(${valueAngle}deg)`;
    }
    if (redlineRef2.current) {
      redlineRef2.current.style.transform = `translate(0%, 0%) rotate(${valueAngle}deg)`;
    }
  }, [linePositionAngle, effectiveBeta]);

  useEffect(() => {
    socket.on("send_step", (data) => {
      setLoadStep(data);
      setShowModal(false);
    });
  }, []);

  useEffect(() => {
    if (loadStep?.step === 3) {
      setLetterExam(true);
    }
  }, [loadStep]);

  const conditionFinder = useCallback(() => {
    const conditionUuid = state?.examDataObj?.condition?.uuid;

    state?.examDataObj?.avaliationModel?.AvaliationModelConditions.forEach(
      (condition) => {
        if (condition.conditionId === conditionUuid) {
          setCondition(condition);
        }
      }
    );
  }, [
    state?.examDataObj?.condition?.uuid,
    state?.examDataObj?.avaliationModel?.AvaliationModelConditions,
    setCondition,
  ]);

  useEffect(() => {
    conditionFinder();
  }, [conditionFinder]);

  return (
    <div className="base-exam">
      <LoadModal open={showModal} />
      {letterExam === false ? (
        <div className="exam-container">
          <ExamLetters />
        </div>
      ) : (
        <div className="exam-container-wrapper">
          <div className="exam-container">
            <animated.div className="line" id="line1" ref={redlineRef1} />
            {condition?.condition?.background === "DYNAMIC" && (
              <animated.img
                src={circleImage}
                className={
                  condition?.condition?.movementSense === "ANTICLOCKWISE"
                    ? `circle rotation-anticlockwise`
                    : "circle rotation-clockwise"
                }
              />
            )}
          </div>
          <div className="exam-container">
            <animated.div className="line" id="line2" ref={redlineRef2} />
            {condition?.condition?.background === "DYNAMIC" && (
              <animated.img
                src={circleImage}
                className={
                  condition?.condition?.movementSense === "ANTICLOCKWISE"
                    ? `circle rotation-anticlockwise`
                    : "circle rotation-clockwise"
                }
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamPage;
