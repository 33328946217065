import React from "react";
import "./examLetter.css";
import letterTest from "../../assets/letter-test.png";

const ExamLetters = () => {
  return (
    <div className="letterContainer">
      <img src={letterTest} alt="letterTest"/>
    </div>
  );
};

export default ExamLetters;